<template>
  <div class="full-page">
    <div class="full-page-container">
      <div class="full-top">
      </div>

      <div class="full-middle">
        <div class="login-container-wrapper">
          <div class="login-container">
            <div class="text-center">
              <img src="/img/logo.svg" alt="Logo" />
            </div>

            <b-form @submit.stop.prevent class="mt-4">
              <label for="login-email">Email</label>
              <b-input
                v-model="loginObj.email"
                :state="validationEmail"
                v-on:keyup.enter="loginNow"
                type="email"
                id="login-email"
              >
              </b-input>
              <b-form-invalid-feedback :state="validationEmail">
                Please enter a valid email.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validationEmail">
                Looks Good.
              </b-form-valid-feedback>
              <br />

              <label for="login-password">Password</label>
              <b-input
                v-model="loginObj.password"
                id="login-password"
                :state="validationPassword"
                v-on:keyup.enter="loginNow"
                type="password"
              >
              </b-input>
              <b-form-invalid-feedback :state="validationPassword">
                Invalid password.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validationPassword">
                Looks Good.
              </b-form-valid-feedback>
              <br />

              <router-link to="forgot-password">
                Lupa password?
              </router-link>
            </b-form>
            <br />

            <button class="e-button bg-color2 w-100 mt-3" @click="loginNow" v-if="!showLoading">
              MASUK
            </button>
            <button class="e-button bg-color2 w-100 mt-3" disabled v-else>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </button>
          </div>
        </div>
      </div>

      <div class="full-bottom"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      loginObj: {
        password: "",
        email: ""
      },
      showLoading: false
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: types.IS_LOGGED_IN
    }),
    validationEmail() {
      return this.loginObj.email.length > 4 && this.loginObj.email.length < 99;
    },
    validationPassword() {
      return (
        this.loginObj.password.length > 7 && this.loginObj.password.length < 99
      );
    }
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },

  methods: {
    ...mapActions({
      setUserInfo: types.LOGIN
    }),
    loginNow() {
      // console.log(this.loginObj);
      // this.setUserInfo({
      //   username: "admin1",
      //   role: "Admin"
      // });
      this.showLoading = true;
      this.setUserInfo(this.loginObj)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.showLoading = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
          this.showLoading = false;
        });
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 1000
      })
    },
  }
};
</script>
